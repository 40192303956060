import React, { useEffect, useState } from "react";
import { sortBy } from "lodash";

interface InteriorFurniture {
  transform: {
    location: [number, number, number];
  };
  name: string;
}

interface HousingFile {
  interiorFurniture: InteriorFurniture[];
}

export const ShoppingList: React.FC = () => {
  const [fileLoaded, setFileLoaded] = useState(false);
  const [housingFile, setHousingFile] = useState<HousingFile>();
  const [houseData, setHouseData] = useState<any>(null);

  useEffect(() => {
    if (fileLoaded) {
      const dedupedItems: { name: string; count: number }[] = [];
      const mappedItems = housingFile?.interiorFurniture.map(
        (item) => item.name
      );
      mappedItems?.forEach((item) => {
        const existingItem = dedupedItems.find((x) => x.name === item);
        if (existingItem) {
          existingItem.count++;
          return;
        }
        dedupedItems.push({ name: item, count: 1 });
      });
      setHouseData(sortBy(dedupedItems, (x) => x.name));
    }
  }, [fileLoaded]);

  const loadFile = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = (ev) => {
        setHousingFile(JSON.parse(ev.target?.result as any));
        setFileLoaded(true);
      };
    }
  };

  if (!fileLoaded) {
    return (
      <>
        <h3>Select a MakePlace file:</h3>
        <input
          type="file"
          accept="application/json,text/json"
          multiple={false}
          name="housingDataFile"
          onChange={loadFile}
        />
      </>
    );
  }

  return (
    <table>
      <tr>
        <th>Item Name</th>
        <th>Count</th>
      </tr>
      {houseData &&
        houseData.map((item: { name: string; count: number }) => {
          return (
            <tr>
              <td>{item.name}</td>
              <td>{item.count}</td>
            </tr>
          );
        })}
    </table>
  );
};
