import "./App.css";
import { ShoppingList } from "./pages/ShoppingList";

function App() {
  return (
    <div className="App">
      <ShoppingList />
    </div>
  );
}

export default App;
